.Login-card {
  background-image: url("../../images/fligno.svg");
  border-radius: 0px !important;
  background-repeat: no-repeat;
  background-size: cover;
}

.log-box1 {
  position: relative !important;
  text-align: center !important;
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}

.log-box1::before {
  content: "";
  background: rgb(236, 240, 242);
  height: 1px;
  width: 100%;
  position: absolute;
  left: 0px;
  top: 13px;
}

.log-span {
  font-size: 0.875rem !important;
  line-height: 1.5 !important;
  color: rgb(119, 126, 137) !important;
  font-weight: 400 !important;
  position: relative !important;
  padding: 0px 12px !important;
  background: rgb(255, 255, 255) !important;
}

.log-sign {
  font-weight: 400 !important;
  font-size: 15px !important;
  padding: 10px 22px !important;
}

.log-sign:hover {
  background-color: #212a3c !important;
}

.log-h2 {
  text-align: left;
  font-weight: 550 !important;
  font-size: 2rem !important;
  line-height: 1.5 !important;
}

/* .box-img {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  margin: 0px auto;
}

@media (min-width: 0px) {
  .box-img {
    position: relative;
    height: auto;
    right: auto;
  }

}

@media (min-width: 1200px) {
  .box-img {
    position: absolute;
    height: 100vh;
    right: -50px;
  }

} */

.log-box {
  align-items: center;
}

@media (min-width: 1200px) {
  .log-box {
    display: flex;
  }
}

@media (min-width: 600px) {
  .log-box {
    display: flex;
  }
}

@media (min-width: 0px) {
  .log-box {
    display: flex;
  }
}

.log-a1 {
  margin: 0px 0px 16px !important;
  font-size: 1rem !important;
  line-height: 1.5 !important;
  font-weight: 500 !important;
  color: rgb(3, 201, 215) !important;
}

@media (max-width: 1200px) {
  .cc-cont {
    height: calc(100vh - 75px);
    width: calc(100vw - 75px) !important;
    overflow-y: scroll;
  }
}

@media (max-width: 720px) {
  .fligno-logo {
    height: 350px;
  }

  .cc-cont {
    height: calc(100vh - 75px);
    width: calc(100vw - 75px) !important;
    overflow-y: scroll;
  }
}

.google-button {
  width: 100%;
  justify-content: center;
  background-color: #fff !important;
  border: none !important;
  border-radius: 5px !important;
  box-shadow: rgb(90 114 123 / 20%) 0px 7px 30px 0px !important;
  height: 56px;
  font-weight: bold !important;
}

.google-button span:nth-of-type(1) {
  padding: 0px !important;
  font-weight: bold !important;
}

.Mui-checked {
  color: #22a9d8 !important;
}

.google-button span:nth-of-type(1) {
  padding: 0px !important;
  font-weight: bold !important;
}
