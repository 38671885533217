#Z-1 {
  animation: hide 2s linear infinite;
}

#Z-2 {
  animation: hide2 2s linear infinite;
}

#Z-3 {
  animation: hide3 2s linear infinite;
}

#Cap {
  animation: bounceCap 8s linear infinite;
}

#Bird1 {
  animation: bounce1 4s linear infinite;
}

#Bird2 {
  animation: bounce2 4s linear infinite;
}

#Bird3 {
  animation: bounce1 4s linear infinite;
}

@keyframes hide {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 50%;
  }
  50% {
    opacity: 100%;
  }
  75% {
    opacity: 50%;
  }
  100% {
    opacity: 0;
  }
}

@keyframes hide2 {
  0% {
    opacity: 100%;
  }
  25% {
    opacity: 50%;
  }
  50% {
    opacity: 0;
  }
  75% {
    opacity: 50%;
  }
  100% {
    opacity: 100%;
  }
}

@keyframes hide3 {
  0% {
    opacity: 50%;
  }
  25% {
    opacity: 100%;
  }
  50% {
    opacity: 50%;
  }
  75% {
    opacity: 0;
  }
  100% {
    opacity: 50%;
  }
}

@keyframes bounceCap {
  0% {
    transform: translateY(0%);
  }
  50% {
    transform: translateY(3%);
  }
  100% {
    transform: translateY(0%);
  }
}

@keyframes bounce1 {
  0% {
    transform: translateY(0%);
  }
  25% {
    transform: translateY(7%);
  }
  50% {
    transform: translateY(0%);
  }
  75% {
    transform: translateY(7%);
  }
  100% {
    transform: translateY(0%);
  }
}

@keyframes bounce2 {
  0% {
    transform: translateY(7%);
  }
  25% {
    transform: translateY(0%);
  }
  50% {
    transform: translateY(7%);
  }
  75% {
    transform: translateY(0%);
  }
  100% {
    transform: translateY(7%);
  }
}
