.header {
  font-size: 1.2125rem !important;
  font-family: "DM Sans", sans-serif !important;
  font-weight: 500 !important;
  //   margin-bottom: 40px !important;
}
.label {
  //   font-weight: 500 !important;
  font-weight: 100 !important;
  font-size: 1rem !important;
  line-height: 1.5 !important;
  color: rgb(119, 126, 137) !important;
  font-family: "DM Sans", sans-serif !important;
  margin-bottom: 10px !important;
}

.alert {
  margin: 20px !important;
}
