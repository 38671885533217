$base-color-green: rgb(0, 194, 146);
$base-color-blue: #0083c9;
$color-grey: rgb(146, 146, 146);

.total-hours {
  color: $color-grey;
  font-size: 19px;
}
.date-range-icon {
  color: $base-color-green;
  margin-right: 4px;
  margin-top: -3px;
}
.man-hours {
  font-size: 27px;
}
.tmh-container {
  margin-top: -7px !important;
}

@media only screen and (max-width: 1195px) and (min-width: 900px) {
  .man-hours {
    font-size: 27px;
  }
  .total-hours {
    font-size: 13px;
  }
  .tmh-icon-bg {
    height: 50px !important;
    width: 50px !important;
  }
  .tmh-icon {
    height: 20px !important;
    width: 20px !important;
  }
}

@media only screen and (max-width: 1036px) and (min-width: 900px) {
  .total-hours {
    display: block;
  }
  .hours-container {
    margin-top: -5px !important;
  }
}
