body {

  margin: 0;
  -moz-osx-font-smoothing: grayscale;
  color: rgba(0, 0, 0, 0.87);
  font-weight: 400;
  font-family: "DM Sans", sans-serif;
  font-size: 1rem;
  line-height: 1.5;
  background-color: rgb(250, 251, 251);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html {
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
  text-size-adjust: 100%;
}

*,
::before,
::after {
  box-sizing: inherit;
}

*,
::before,
::after {
  box-sizing: inherit;
}