.greeting-card {
  padding: 14px !important;
  margin: 15px !important;
  border-radius: 10px !important;
  box-shadow: rgb(90 114 123 / 11%) 0px 7px 30px 0px !important;
  position: relative;
  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    transform: unset;
    background: url("../../images/freepik.png") 0% 0% / 75% no-repeat;
    background-repeat: no-repeat;
  }
}
@media (min-width: 0px) {
  .greeting-card::before {
    background-position: right -30px top 25px;
  }
}
.greeting-h4 {
  font-size: 1.2125rem !important;
  line-height: 1.5 !important;
  font-family: "DM Sans", sans-serif !important;
  font-weight: 600 !important;
}
.greeting-h3 {
  font-weight: 500 !important;
  font-size: 1.125rem !important;
  line-height: 1.5 !important;
  color: rgb(119, 126, 137) !important;
  font-family: "DM Sans", sans-serif !important;
}
.greeting-box {
  display: flex;
  align-items: center;
}

.greeting-box1 {
  margin: auto;
}
.greeting-button {
  min-height: 36px !important;
  min-width: 0px !important;
  padding: 0px !important;
  color: #fff !important;
  width: 48px !important;
  height: 48px !important;
  background-color: rgb(0, 194, 146) !important;
  box-shadow: rgb(0 0 0 / 31%) 0px 0px 1px 0px,
    rgb(0 0 0 / 25%) 0px 4px 6px -2px;
}

.greeting-b1 {
  color: #fff !important;
  background-color: #212a3c !important;
  text-transform: none !important;
  box-shadow: none !important;
  font-size: 15px !important;
  padding: 6px 10px !important;
  margin: 15px 0px 0px !important;
  border-radius: 5px !important;
  height: 40px !important;
}
.greet-fligno {
  background-color: #212a3c !important;
  color: #fff !important;
  height: 30px;
  font-weight: 600 !important;
  letter-spacing: 0.1em !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
}
.greeting-h4-g {
  font-size: 0.95rem !important;
  line-height: 2 !important;
  font-weight: 600 !important;
}

.date-card {
  border-radius: 10px !important;
  padding: 14px !important;
  margin: 15px !important;
  box-shadow: rgb(90 114 123 / 11%) 0px 7px 30px 0px !important;
  height: 215.5px !important;
}

.date-box {
  display: flex;
  align-items: flex-start;
}

.date-h4 {
  font-weight: 500 !important;
  font-size: 1.125rem !important;
  line-height: 1.5 !important;
  font-family: "DM Sans", sans-serif !important;
}
.date-box {
  margin-left: auto;
}
.date-button {
  min-height: 36px !important;
  min-width: 0px !important;
  padding: 0px !important;
  color: #fff !important;
  width: 48px !important;
  height: 48px !important;
  background-color: rgb(0, 194, 146) !important;
  box-shadow: rgb(0 0 0 / 31%) 0px 0px 1px 0px,
    rgb(0 0 0 / 25%) 0px 4px 6px -2px;
}
.date-h1 {
  margin: 20px 0px 0px !important;
  font-size: 1.875rem !important;
  line-height: 1.5 !important;
  font-family: "DM Sans", sans-serif !important;
  font-weight: 500 !important;
}
.date-h6 {
  font-size: 1.175rem !important;
  line-height: 1.5 !important;
  font-family: "DM Sans", sans-serif !important;
  font-weight: 400 !important;
  color: rgb(119, 126, 137) !important;
  opacity: 0.6 !important;
}
.card-inout {
  border-radius: 10px !important;
  padding: 14px !important;
  margin: 15px !important;
  box-shadow: rgb(90 114 123 / 11%) 0px 7px 30px 0px !important;
  border-width: 0px !important;
}
.card-typ0 {
  font-weight: 500 !important;
  font-size: 1rem !important;
  line-height: 1.5 !important;
  font-family: "DM Sans", sans-serif !important;
  color: rgb(0, 194, 146) !important;
}
.card-typ01 {
  font-weight: 500 !important;
  font-size: 1rem !important;
  line-height: 1.5 !important;
  font-family: "DM Sans", sans-serif !important;
  color: #212a3c !important;
}
.card-typ {
  font-weight: 400 !important;
  font-size: 0.6rem !important;
  line-height: 1.2 !important;
  letter-spacing: 0.0075em !important;
  color: rgb(119, 126, 137) !important;
}

.card-media {
  width: 80px !important;
  margin: auto !important;
  border-radius: 10px !important;
}

.in {
  background-color: rgb(235, 250, 242) !important;
  color: rgb(0, 194, 146) !important;
  border-radius: 5px !important;
}
.out {
  background-color: #b9c5de !important;
  color: #212a3c !important;
  border-radius: 5px !important;
}
// .timest{
//     display: flex;
//     align-items: center;
// }

.timest-h4 {
  font-weight: 400 !important;
  font-size: 0.875rem !important;
  line-height: 1.43 !important;
  letter-spacing: 0.01071em !important;
}
.timest-h3 {
  color: rgba(0, 0, 0, 0.6) !important;
}
.timest-button {
  background-color: rgb(0, 194, 146) !important;
  border-radius: 5px !important;
  text-transform: none !important;
  padding: 6px 16px !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
}
.card-leave {
  border-radius: 10px !important;
  margin: 15px !important;
  box-shadow: rgb(90 114 123 / 11%) 0px 7px 30px 0px !important;
  padding: 0px !important;
}

.leave-box {
  display: flex;
  align-items: center;
  padding-top: 16px;
  margin: auto;
  width: fit-content;
}

.sick-button {
  background-color: rgb(0, 194, 146) !important;
  color: #fff !important;
  width: 45px !important;
  height: 45px !important;
}
.vacation-button {
  background-color: #0083c9 !important;
  color: #fff !important;
  width: 45px !important;
  height: 45px !important;
}
.v-body1 {
  color: #0083c9 !important;
  font-size: 1.5rem !important;
}
.s-body1 {
  color: rgb(0, 194, 146) !important;
  font-size: 1.5rem !important;
}
.card-bar {
  border-radius: 10px !important;
  padding: 14px !important;
  margin: 15px !important;
  box-shadow: rgb(90 114 123 / 11%) 0px 7px 30px 0px !important;
}
.h3-bar {
  font-weight: 500 !important;
  font-size: 1.125rem !important;
  line-height: 1.5 !important;
  font-family: "DM Sans", sans-serif !important;
}
.h-h6 {
  display: flex !important;
  align-items: center !important;
  font-size: 0.875rem !important;
  line-height: 1.5 !important;
  color: #00c292 !important;
}
.b-b1 {
  margin: 0px 5px 0px 0px !important;
}
.ch-action {
  position: relative !important;
  div.MuiCardHeader-action {
    position: absolute;
    right: 0;
    width: 40% !important;
    margin-top: 2px !important;
    margin-right: 0px !important;
  }
}

@media (min-width: 1333px) {
  .tig-adjust {
    padding-top: 10px !important;
  }
}

.activity-card {
  border-radius: 10px !important;
  padding: 14px !important;
  margin: 15px !important;
  box-shadow: rgb(90 114 123 / 11%) 0px 7px 30px 0px !important;
  height: 454.75px !important;
}

.announcement-card {
  border-radius: 10px !important;
  box-shadow: rgb(90 114 123 / 11%) 0px 7px 30px 0px !important;
}

.tl-comp {
  padding: 0px !important;
  margin-bottom: 0px !important;
  margin-top: 0px !important;
}
.tl-oc {
  font-family: "DM Sans", sans-serif !important;
  margin: 0px auto 0px 0px !important;
  flex: 0 1 0% !important;
  white-space: nowrap !important;
  padding: 7px 16px 6px 16px !important;
  font-weight: 500 !important;
}
.birth-h6 {
  font-size: 0.875rem !important;
  font-family: "DM Sans", sans-serif !important;
}
.birth-name {
  font-size: 0.775rem !important;
}
.time-box {
  overflow-y: scroll;
  height: 370px;
}
.event-head {
  div.MuiCardHeader-action {
    margin-top: 0px !important;
    margin-right: 0px !important;
  }
}
.qt-card {
  border-radius: 10px !important;
  padding: 14px !important;
  margin: 15px !important;
  box-shadow: rgb(90 114 123 / 11%) 0px 7px 30px 0px !important;
  height: 454.76px !important;
}

.handbook-card {
  border-radius: 10px !important;
  padding: 14px !important;
  margin: 15px !important;
  box-shadow: rgb(90 114 123 / 11%) 0px 7px 30px 0px !important;
  height: 170px !important;
}

.auth-h6 {
  font-size: 1rem !important;
  font-weight: 400 !important;
  line-height: 1.75 !important;
}
.auth-b1 {
  font-size: 14px !important;
  margin: 16px 0px 0px !important;
}

.qoute-body {
  overflow: hidden;
  max-height: 100px;
  &:hover {
    overflow-y: auto;
  }
}

.tm-card {
  border-radius: 10px !important;
  padding: 14px !important;
  margin: 15px !important;
  box-shadow: rgb(90 114 123 / 11%) 0px 7px 30px 0px !important;
}
.picker-card {
  border-radius: 10px !important;
  margin: 15px !important;
  box-shadow: rgb(90 114 123 / 11%) 0px 7px 30px 0px !important;
  padding: 0px !important;
}
.ts-h6 {
  font-family: "DM Sans", sans-serif !important;
  line-height: 1.57 !important;
  font-size: 0.875rem !important;
}
.ts-h61 {
  font-family: "DM Sans", sans-serif !important;
  line-height: 1.57 !important;
  font-size: 0.7rem !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
}
.ts-h7 {
  font-size: 0.65rem !important;
  line-height: 1.5 !important;
  font-family: "DM Sans", sans-serif !important;
  color: rgb(119, 126, 137) !important;
  font-weight: 400 !important;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.td-th {
  text-align: center !important;
  padding: 5px !important;
  border-bottom: 1px solid rgb(224, 224, 224) !important;
}
.ts-chip {
  height: 14px !important;
  border-radius: 3px !important;
  margin-top: -6px;
  color: #fff !important;
  left: 0 !important;
  right: 0 !important;
  margin-left: auto !important;
  margin-right: auto !important;
  font-size: 0.6rem !important;
  line-height: 1.5 !important;
  font-family: "DM Sans", sans-serif !important;
  background-color: #00c292 !important;
  font-weight: 400 !important;
  span.MuiChip-label {
    padding: 0px !important;
  }
  width: 38px !important;
}
.ts-card {
  overflow: unset !important;
  position: relative !important;
  box-shadow: none !important;
  height: 30px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}
.ts-chipbox {
  display: flex;
  justify-content: space-evenly;
}
.h6-min {
  // min-width: 45px !important;
  padding: 4px 10px 0px 10px !important;
  margin: 5px !important;
}

.td-button {
  min-width: 0px !important;
  height: 28px !important;
  background-color: rgb(119, 126, 137) !important;
  font-size: 0.75rem !important;
  font-family: "DM Sans", sans-serif !important;
  &:hover {
    background-color: #212a3c !important;
    color: #fff !important;
    transition: ease 0.3s;
  }
}
.bottom-button {
  min-width: 73px !important;
  background-color: rgb(119, 126, 137) !important;
  font-size: 0.75rem !important;
  font-family: "DM Sans", sans-serif !important;
  height: 28px !important;
  &:hover {
    background-color: #212a3c !important;
    color: #fff !important;
    transition: ease 0.3s;
  }
}
.bottom-button {
  min-width: 73px !important;
  background-color: rgb(119, 126, 137) !important;
  font-size: 0.75rem !important;
  font-family: "DM Sans", sans-serif !important;
  height: 28px !important;
  &:hover {
    background-color: #212a3c !important;
    color: #fff !important;
    transition: ease 0.3s;
  }
}
.td-input {
  min-width: 0px !important;
  background-color: #fff !important;
  font-size: 0.75rem !important;
  font-family: "DM Sans", sans-serif !important;
  height: 38px !important;
  width: 38px;
}

@media (max-width: 1200px) {
  .adjust-w {
    width: 100% !important;
  }
}
.leave-modal {
  height: calc(100vh - 75px);
  width: calc(100vw - 150px);
}
.cc-static-dp {
  overflow-y: scroll;
  height: calc(100vh - 225px);
  margin: 0px 20px 10px;
  border: 1px solid #d4d4d4;
  border-radius: 7px;
}
@media (max-width: 720px) {
  .leave-modal {
    width: calc(100vw - 30px);
    height: calc(100vh - 50px);
    overflow-y: scroll !important;
  }
  .h6-adjust {
    width: 87px !important;
    overflow: hidden !important;
  }

  .ts-card {
    width: 40px !important;
  }
  .ts-h61 {
    overflow: hidden !important;
    padding: 10px 2px 0px 2px !important;
    margin: 0px !important;
    font-size: 0.55rem !important;
  }
}

.h6-adjust {
  width: 156px;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
}

.footer-button {
  background: url("../../images/waves.svg") no-repeat;
  font-weight: 700 !important;
}

.waves2 {
  background: url("../../images/waves2.svg");
  color: #fff !important;
}

.login-modal {
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  box-shadow: rgb(90 114 123 / 11%) 0px 7px 30px 0px !important;
  padding: 14px !important;
  border-radius: 10px !important;
}

.feedback-modal {
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  box-shadow: rgb(90 114 123 / 11%) 0px 7px 30px 0px !important;
  padding: 14px !important;
  border-radius: 10px !important;
}

.feedback-modal::-webkit-scrollbar {
  display: none;
}

.modal-detail {
  display: flex;
  justify-content: center;
  align-items: center;
}

.fc-g {
  margin: 10px 0px 10px 0px !important;
  div.MuiOutlinedInput-root {
    font-size: 0.875rem !important;
  }
  label.MuiInputLabel-root {
    font-size: 0.875rem !important;
  }
}

.h-text {
  font-size: 0.65rem !important;
  font-family: "DM Sans", sans-serif !important;
}

.MuiMenuItem-root {
  font-size: 0.875rem !important;
}

.cancel-button {
  background-color: rgb(228, 106, 118) !important;
  border-radius: 5px !important;
  text-transform: none !important;
  padding: 6px 16px !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
}

.picker-sidebar {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  height: auto;
  max-width: 160px;
  flex-wrap: wrap;
  padding: 0px 10px 0px 10px;
  background-color: #ececec;
  border-radius: 5px;
  margin: 5px 5px 0px 0px;
}

.time-slider {
  span.MuiSlider-mark {
    width: 5px !important;
    height: 5px !important;
    border-radius: 5px !important;
  }
  span.MuiSlider-thumb {
    width: 15px !important;
    height: 15px !important;
    transform: translate(-30%, -50%) !important;
  }
  span[data-index="0"].MuiSlider-mark {
    left: 0.7% !important;
  }
}

.picker-container {
  display: flex;
  flex-direction: row;
}
.picker-d {
  display: flex;
  flex-direction: column-reverse;
  width: 100%;
}
.typ-h4-d {
  font-size: 1.5rem !important;
  white-space: nowrap !important;
}

.typ-over {
  height: 36px;
  background-color: #444;
  border-radius: 5px;
  color: #fff;
  display: flex;
}

.MuiFormLabel-root {
  font-size: 0.85rem !important;
}
.MuiSlider-valueLabelOpen {
  font-size: 0.65rem !important;
  border-radius: 5px !important;
}
.MuiSlider-root {
  height: 10px !important;
}

.MuiSlider-rail {
  padding-right: 7px !important;
}
.ck-editor__editable_inline {
  min-height: 400px;
}

.card-webcam {
  height: 160px !important;
  width: 160px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100% !important;
}

.box-drop {
  position: absolute;
  height: 175px;
  width: 175px;
  border-radius: 100%;

  display: flex;
  align-items: center;
  overflow: hidden;
  justify-content: center;

  &::after {
    content: "";
    position: absolute;
    inset: 4px;
    background: #fff;
    border-radius: 100%;
  }
}
.box-ch {
  animation: rotateAni 4s linear infinite;
  position: absolute;
  height: 100%;
  background: linear-gradient(315deg, #00c292, #020306);
  transition: width 3s ease-in;
}

@keyframes rotateAni {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes expand {
  0% {
    width: 20%;
  }
  100% {
    width: 110%;
  }
}

.cap-box {
  position: relative !important;
  text-align: center !important;
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}
.cap-box::before {
  content: "";
  background: rgb(236, 240, 242);
  height: 1px;
  width: 100%;
  position: absolute;
  left: 0px;
  top: 13px;
}
.cap-span {
  font-size: 0.875rem !important;
  line-height: 1.5 !important;
  color: rgb(119, 126, 137) !important;
  font-weight: 400 !important;
  position: relative !important;
  padding: 0px 12px !important;
  background: rgb(255, 255, 255) !important;
}
.timesheet-calendar-hit {
  background-color: #61a644 !important;
  border-radius: 5px !important;
  color: #fff !important;
  border: 1px solid #e2e8f0 !important;
}

.timesheet-calendar-over {
  background-color: #3fb6ad !important;
  color: #fff !important;
  border-radius: 5px !important;
  border: 1px solid #e2e8f0 !important;
}

.timesheet-calendar-under {
  background-color: #eb7a24 !important;
  color: #fff !important;
  border-radius: 5px !important;
  border: 1px solid #e2e8f0 !important;
}

.barChartSkeleton {
  height: 196.45px !important;
  transform: scale(1) !important;
  margin-left: 20px !important;
}

.barChartSkeleton2 {
  height: 20px !important;
  transform: scale(1) !important;
  margin-left: 20px !important;
}

.time-h6 {
  font-family: "DM Sans", sans-serif !important;
  line-height: 1.57 !important;
  font-size: 0.6rem !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
}

.reaction-buttons {
  min-width: 45px !important;
  margin: 2px !important;
}
.reaction-header {
  padding: 10px 0 0 20px !important;
}

.ck.ck-balloon-panel {
  z-index: 10500 !important;
}

.dot1,
.dot2,
.dot3 {
  background: #fff;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  margin: 10px;
}

.dot1 {
  animation: jump 1.6s -0.32s linear infinite;
  background: #ffac30;
}
.dot2 {
  animation: jump 1.6s -0.16s linear infinite;
  background: #28e2be;
}
.dot3 {
  animation: jump 1.6s linear infinite;
  background: #fa6a6c;
}
@keyframes jump {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(2);
    transform: scale(2);
  }
}
.hexColorInputComponent {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 0.875rem !important;
  line-height: 1.4375em;
  letter-spacing: 0.00938em;
  color: rgba(0, 0, 0, 0.87);
  box-sizing: border-box;
  position: relative;
  cursor: text;
  align-items: center;
  width: 100%;
  position: relative;
  border-radius: 4px;
  padding: 8.5px 14px;
  border-radius: 5px;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.23);
}

.drawer-app {
  appearance: none !important;
  // width: 56px !important;
  // height: 56px !important;
  box-shadow: rgb(0 0 0 / 31%) 0px 0px 1px 0px,
    rgb(0 0 0 / 25%) 0px 4px 6px -2px !important;
  // color: rgb(255, 255, 255) !important;
  position: absolute !important;
  right: 23px !important;
  bottom: 15px !important;
  z-index: 10;
  border-radius: 25px !important;
  background-color: #00c292 !important;
  // min-height: 36px !important;
  span.MuiButton-endIcon {
    margin: 0;
    svg {
      color: #fff;
    }
  }
}
.pop-up-pulse {
  color: #fff !important;
  background-color: transparent !important;
  text-transform: none;
  cursor: pointer !important;
}

.draw-end {
  animation: rotate 4000ms infinite linear;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.motd {
  animation: shake 0.5s;

  animation-iteration-count: infinite;
}

@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }
  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }
  20% {
    transform: translate(-2px, 0px) rotate(1deg);
  }
  30% {
    transform: translate(2px, 1px) rotate(0deg);
  }
  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    transform: translate(-0px, 2px) rotate(-1deg);
  }
  60% {
    transform: translate(-2px, 1px) rotate(0deg);
  }
  70% {
    transform: translate(2px, 1px) rotate(-1deg);
  }
  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }
  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }
  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}
