.span-item {
  position: absolute !important;
  top: -50px;
  font-weight: 800 !important;
  font-size: 0.85rem !important;
}
.fligno-waves {
  min-height: 220px;
}

.box-ani {
  opacity: 0.96;
  width: 300px;
  height: 300px;
  position: absolute;
  top: -142.85714px;
  left: -24px;
  right: 0;
  bottom: 0;
  margin: auto;
}

.span-ani {
  display: block;
  position: absolute;
  bottom: 0;
  box-sizing: border-box;
  border-radius: 15px 15px 15px 15px;
  text-align: center;
  width: 25px;
}

.span-logo {
  height: 0;
  animation: c 3s 0.1s infinite ease-in-out;
}
.wave1 {
  height: 0;
  animation: c 3s 0.2s infinite ease-in-out;
}
.wave2 {
  height: 0;
  animation: c 3s 0.3s infinite ease-in-out;
}
.wave3 {
  height: 0;
  animation: c 3s 0.4s infinite ease-in-out;
}
.wave4 {
  height: 0;
  animation: c 3s 0.5s infinite ease-in-out;
}
.wave5 {
  height: 0;
  animation: c 3s 0.6s infinite ease-in-out;
}
.wave6 {
  height: 0;
  animation: c 3s 0.7s infinite ease-in-out;
}
.wave7 {
  height: 0;
  animation: c 3s 0.8s infinite ease-in-out;
}
.wave8 {
  height: 0;
  animation: c 3s 0.9s infinite ease-in-out;
}
.wave9 {
  height: 0;
  animation: c 3s 1s infinite ease-in-out;
}
.wave10 {
  height: 0;
  animation: c 3s 1.1s infinite ease-in-out;
}
.wave11 {
  height: 0;
  animation: c 3s 1.2s infinite ease-in-out;
}
.wave12 {
  height: 0;
  animation: c 3s 1.3s infinite ease-in-out;
}
.wave13 {
  height: 0;
  animation: c 3s 1.4s infinite ease-in-out;
}
.wave14 {
  height: 0;
  animation: c 3s 1.5s infinite ease-in-out;
}
.wave15 {
  height: 0;
  animation: c 3s 1.6s infinite ease-in-out;
}
.wave16 {
  height: 0;
  animation: c 3s 1.7s infinite ease-in-out;
}
.wave17 {
  height: 0;
  animation: c 3s 1.8s infinite ease-in-out;
}
.wave18 {
  height: 0;
  animation: c 3s 1.9s infinite ease-in-out;
}
.wave19 {
  height: 0;
  animation: c 3s 2s infinite ease-in-out;
}
.wave20 {
  height: 0;
  animation: c 3s 2.1s infinite ease-in-out;
}

@keyframes c {
  0% {
    height: 0px;
    background-color: #008ffb;
    color: #008ffb;
  }
  50% {
    height: 130px;
    background-color: #00d5ff;
    color: #00d5ff;
  }
  100% {
    height: 0px;
    background-color: #008ffb;
    color: #008ffb;
  }
}
