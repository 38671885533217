#Sun {
  animation: bounce 6s linear infinite;
}

@keyframes bounce {
  0% {
    transform: translateY(0%);
  }
  25% {
    transform: translateY(7%);
  }
  50% {
    transform: translateY(0%);
  }
  75% {
    transform: translateY(7%);
  }
  100% {
    transform: translateY(0%);
  }
}

#Cloud1,
#Cloud2 {
  animation: move 6s linear infinite;
}

@keyframes move {
  0% {
    transform: translateX(0%);
  }
  25% {
    transform: translateX(-2%);
  }
  50% {
    transform: translateX(0%);
  }
  75% {
    transform: translateX(4%);
  }
  100% {
    transform: translateX(0%);
  }
}
