.box-divider {
  width: 1px;
  background-color: rgba(255, 255, 255, 0.6);
  height: 25px;
  margin-left: 8px;
  margin-right: 8px;
}

.header-right {
  justify-content: flex-end;
  display: flex !important;
  align-items: center;
  flex-grow: 3;
}

.avatar-Korra {
  margin-right: 5px !important;
  width: 30px !important;
  height: 30px !important;
}

.T_1 {
  color: rgb(119, 126, 137) !important;
  font-size: 1rem !important;
  line-height: 1.5 !important;
}

.T_2 {
  padding-left: 5px !important;
  font-size: 1rem !important;
  line-height: 1.5 !important;
  font-weight: 700 !important;
  color: #fff !important;
}

.UserProfile {
  margin-bottom: 8px !important;
}

.UserProfile-inner {
  display: flex !important;
  align-items: center;
}

.UserProfile-typo {
  font-size: 1.125rem !important;
  line-height: 1.5 !important;
  font-weight: 500 !important;
  letter-spacing: 0em !important;
}

.B_1 {
  margin-top: 24px !important;
  margin-bottom: 24px !important;
}

.B_1-inner {
  display: flex !important;
  align-items: center !important;
}

.B_1-avatar {
  width: 90px !important;
  height: 90px !important;
}

.B_1-typo {
  margin-left: 16px !important;
}

.B_1-typo-1 {
  font-size: 1.125rem !important;
  line-height: 1.235 !important;
  font-weight: 500 !important;
  letter-spacing: 0em !important;
}

.B_1-typo-2 {
  font-size: 0.875rem !important;
  line-height: 1.5 !important;
  font-weight: 400 !important;
  color: rgb(119, 126, 137) !important;
  letter-spacing: 0em !important;
}

.B_2 {
  display: flex !important;
  align-items: center !important;
}

.B_2-typo {
  margin: 0px 8px 0px 0px !important;
  color: rgb(153, 171, 180) !important;
  display: flex !important;
  align-items: center !important;
  letter-spacing: 0em !important;
}

.B_2-typo-2 {
  font-size: 00.875rem !important;
  line-height: 1.5 !important;
  font-weight: 500 !important;
  color: rgb(119, 126, 137) !important;
}

.upl-menuitem {
  padding: 24px 16px !important;
}

.Box_loop {
  display: flex !important;
  align-items: center !important;
}

.Box_loop_button {
  min-width: 50px !important;
  height: 40px !important;
  width: 45px !important;
  border-radius: 10px !important;
}

.Box_loop_box {
  margin-left: 16px !important;
}

.Box_loop_t1 {
  font-weight: 500 !important;
  font-size: 1rem !important;
  line-height: 1.235 !important;
}

.Box_loop_t2 {
  font-weight: 400 !important;
  font-size: 00.875rem !important;
  line-height: 1.5 !important;
  color: rgb(119, 126, 137) !important;
}

.Logout_button {
  width: 100% !important;
  margin: 16px 0px 0px !important;
  font-size: 15px !important;
  font-weight: 400 !important;
  border-radius: 5px !important;
  outline: 0px !important;
  text-transform: none !important;
  background-color: #22a9d8 !important;
}

.Logout_button:hover {
  background-color: #212a3c !important;
}

.h6-b2 {
  font-size: 0.875rem !important;
  line-height: 1.5 !important;
  color: rgb(119, 126, 137) !important;
  font-weight: 400 !important;
}

.ham-menu {
  justify-content: flex-start;
  display: flex !important;
  flex-grow: 0;
}

@media (max-width: 768px) {
  .header-box {
    width: 60px !important;
  }
  .header-ham {
    display: block !important;
  }
  .header-h1 {
    display: none !important;
  }
}
