$sidebar-bg-color: #fff;
$submenu-bg-color: #fff !default;
$sidebar-width: 270px !default;
$icon-bg-color: rgb(238, 238, 238) !default;
$icon-size: 43px !default;
$submenu-indent: 65px !default;
$sidebar-color: rgba(0, 0, 0, 0.87) !default;
$highlight-color: rgb(119, 126, 137) !default;
$submenu-bg-color-collapsed: #fff !default;
@import "~react-pro-sidebar/dist/scss/styles.scss";
.pro-sidebar {
  height: calc(100vh - 75px) !important;
  box-shadow: rgb(90 114 123 / 11%) 0px 7px 30px 0px !important;
}
.pro-sidebar > .pro-sidebar-inner {
  border-radius: 0px 5px 5px 0px !important;
  box-shadow: rgb(90 114 123 / 11%) 0px 7px 30px 0px !important;
}
